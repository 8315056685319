<template>
  <div class="Basics">
    <div class="u-info" v-if="userInfo">
      <div class="info_header">
        <div class="info_header_left">基础信息</div>
        <div class="info_header_right" @click="infoEdit" v-if="isInfoEdit ==false">编辑</div>
      </div>
      <div class="base-info-content">
      <div class="userimg">
                <img
                  v-if="userInfo.avatar"
                  class="headerimg"
                  :src="imgPrefix + userInfo.avatar"
                  alt=""
                />
          
              </div>
      <div class="base-info-content-show">
         
         
        <div class="item">
          <span class="content-show-l">昵称： </span>
          
          <input type="text" v-if="isInfoEdit" :value="userInfo.realName" />
          <span class="content-show-info" v-else>{{userInfo.realName}}</span>
        </div>
        <div class="item">
          <span class="content-show-l">登录邮箱：</span>
          <input type="text" v-if="isInfoEdit"  :value="userInfo.email" />
          <span class="content-show-info" v-else>{{userInfo.email}}</span>
        </div>
        <!-- <div class="item">
          <span class="content-show-l">会员类型：</span>
          <input type="text" :value="userInfo.groupTitle" />
          <span class="content-show-r" v-if="userInfo">{{
            userInfo.email
          }}</span>
        </div> -->
        <div class="item">
          <span class="content-show-l">手机绑定：</span>
        
          <input type="text"  v-if="isInfoEdit" :value="userInfo.phone" />
            <span class="content-show-r" v-else>{{
            userInfo.phone
          }}</span>
        </div>
        <div class="item">
          <el-button type="primary" @click="save" v-if="isInfoEdit">保存</el-button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getuserinfo} from "@/api";
export default {
  name: "Basics",
   data() {
    return {
         userInfo:{},
         isInfoEdit:false
    }
    },
  methods: {
    save() {
      this.isInfoEdit = false
      console.log("保存");
    },
    infoEdit(){
this.isInfoEdit = true
    }
  },
  mounted(){
     getuserinfo().then(res=>{
      this.userInfo = res.data
      console.log(this.userInfo)
    }).catch(err=>{
      localStorage.setItem("userName", ""); 
      this.userInfo = {}
    })
    
  },
  computed: {
    // userInfo() {
    //   return this.$store.state.userInfo;
    // },
  },
  
};
</script>

<style lang="less" scoped>
.Basics {
  padding: 10px 15px;
  .u-info {
    background: #fff;
    margin-bottom: 10px;
    .info_header {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-weight: 600;
      color: #2e2e2e;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
      .info_header_right{
         font-size: 15px;
         font-weight: 500;
         color: #314483;
         cursor: pointer;
         margin-right: 20px;
      }
      .update {
        color: #074ad1;
        font-size: 14px;
        font-weight: normal;
        margin-right: 75px;
      }
      .name {
        color: rgb(141, 158, 167);
        font-size: 0.875rem;
        font-weight: 400;
      }
      .header-right {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        i {
          margin-right: 0.3rem;
          color: #409eff;
        }
      }
    }
    h2 {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-weight: 600;
      color: #2e2e2e;
      border-bottom: 1px solid #f0f0f2;
    }
    .base-info-content{
       display: flex;
    }
       .userimg {
           
            width: 96px;
            height: 96px;
           
            border-radius: 50%;
            margin-top: 15px;
           
            .headerimg {
              width: 88%;
              border-radius: 50%;
              margin-left: 6%;
              margin-top: 6%;
            }
           
          }
    .base-info-content-show {
      padding: 16px 58px 16px 20px;
      display: flex;
      position: relative;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
   
    }
    .item {
      width: 50%;
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 32px;
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      input {
        width: 100%;
        margin-left: 15px;
        padding: 10px 7px;
        background: #f1f1f1;
        border: 1px solid #dcdcdc;
        outline: none;
        margin-top: 5px;
        &:hover {
          cursor: default;
        }
      }
      button {
        margin-left: 15px;
      }
      .content-show-l {
        width: 86px;
        color: #96909C;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        font-size: 15px;
        padding-left: 16px;
        display: flex;
        box-sizing: content-box;
        justify-content: space-between;
      }
      .content-show-info{
        color:#1D2129;
      }
      .content-show-r {
        color: #222226;
        word-break: break-all;
      }

      a {
        color: #fc5531;
        font-weight: 600;
        padding-left: 5px;
        text-decoration: underline;
      }
    }
  }
}
</style>